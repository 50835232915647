import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Form from '../../../../components/admin/messages/form';
import envData from '../../../../../../config/env.json';
import { Loader } from '../../../../components/helpers';
import { userSelector } from '../../../../redux/selectors';
import { hardGoTo as navigate } from '../../../../redux/actions';
import { User } from '../../../../redux/prop-types';
import AdminLayout from '../../../../components/layouts/admin-layout';
import BackButton from '../../../../components/ui/BackButton';

import './message-form.css';

const { apiLocation } = envData;

type MessageFormProps = {
  readonly user: User;
  readonly location: {
    search: string;
  };
  readonly navigate: (location: string) => void;
};

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

const mapDispatchToProps = {
  navigate
};

function MessageForm({
  location,
  user,
  navigate
}: MessageFormProps): JSX.Element {
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const isUserAdmin = user.type === 'admin';

  if (isUserAdmin) {
    navigate(`${apiLocation}/signin`);
    return <Loader fullScreen={true} />;
  }

  if (!isUserAdmin) {
    navigate(`${apiLocation}/signin`);
    return <Loader fullScreen={true} />;
  }

  return (
    <AdminLayout title={'DEVstart | Cadastrar Mensagem'}>
      <div className='button-back-area'>
        <BackButton prevUrl='/admin/messages/message-dashboard' />
      </div>

      <div className='new-message-container'>
        <h1>{id ? 'Edição de mensagem' : 'Cadastro de mensagem'}</h1>
        <Form messageId={id} />
      </div>
    </AdminLayout>
  );
}

MessageForm.displayName = 'MessageForm';

export default connect(mapStateToProps, mapDispatchToProps)(MessageForm);
